import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment,
    UrlTree
} from '@angular/router';
import { steps } from '@environment/steps';
import { UserService } from '@ff/user';
import { User } from '@shared-models/user';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AdminGuard implements CanActivate, CanActivateChild, CanLoad {
    authUser: User;

    constructor(
        private userService: UserService,
        private location: Location,
        private router: Router
    ) {
        this.authUser = this.userService._user.getValue();
    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {

        const currentUser = new User(this.authUser);

        if (currentUser && (currentUser.isAdmin() || currentUser.isManager() || currentUser.isKeyBusinessUser() || currentUser.isSalesDirector())) {
            return true;
        } else if (currentUser) {
            this.router.navigate([steps.HOME]);
        } else {
            this.router.navigate([steps.LOGIN]);
        }

    }

    canActivateChild(
        childRoute: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        return this.canActivate(childRoute, state);
    }

    canLoad(
        route: Route,
        segments: UrlSegment[]
    ): Observable<boolean> | Promise<boolean> | boolean {
        const currentUser = new User(this.authUser);
        if (currentUser && currentUser.isAdmin()) {
            return true;
        }
        this.router.navigate([steps.HOME]);
    }
}
