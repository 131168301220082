import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@ff/auth';
import { UserService } from '@ff/user';
import { LocalStorageService } from '@shared-services/local-storage.service';

@Component({
	selector: 'app-auth-logout',
	templateUrl: './logout.component.html',
	styleUrls: ['./logout.component.scss'],
})
export class LogoutComponent {
	constructor(
		private _authService: AuthService,
		private userService: UserService,
		private localStorage: LocalStorageService,
		private _router: Router
	) {
		this._authService.logout().then((done: boolean) => {
			this.localStorage.cleanDataBase();
			this.userService.getUser().next(null);
			this._router.navigate(['/']);
		});
	}
}
