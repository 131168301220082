import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { endpoints } from '@environment/endpoints';
import { environment } from '@environment/environment';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LanguageSwitcherService {

  currentLang = new BehaviorSubject<string>(null);

  constructor(private http: HttpClient) {
  }

  switchLanguage(langCode: string): Observable<any> {
    const params = new HttpParams().append('lang', langCode);
    return this.http.post(environment.api.host + endpoints.switch_language, params);
  }

  getCurrentLanguage(): Observable<any> {
    return this.http.get(environment.api.host + endpoints.current_language);
  }

  getLanguages(): Observable<any> {
    return this.http.get(environment.api.host + endpoints.languages);
  }

  updateCurrentLangSubject(lang: string) {
    this.currentLang.next(lang);
  }
}
