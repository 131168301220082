import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SubSink } from 'subsink';
import { LanguageSwitcherService } from './language-switcher.service';

@Component({
    selector: 'app-language-switcher',
    templateUrl: './language-switcher.component.html',
    styleUrls: ['./language-switcher.component.scss']
})
export class LanguageSwitcherComponent implements OnInit, OnDestroy {
    currentLang = 'FR';
    allLangs = [{code: 'fr', short_label: 'FR'},
        {code: 'en', short_label: 'EN'},
      {code: 'es', short_label: 'ES'}];
    availableLangs = [{code: 'fr', short_label: 'FR'},
        {code: 'en', short_label: 'EN'},
      {code: 'es', short_label: 'ES'}];
    private subs = new SubSink();

    constructor(
        private languageSwitcherService: LanguageSwitcherService,
        private translate: TranslateService
    ) {}

    ngOnInit() {
        if (localStorage.getItem('sepa_lang') !== null) {
            this.currentLang = localStorage.getItem('sepa_lang');
        }
        this.updateCurrentLanguage();
    }

    switch(langCode: string) {
        localStorage.setItem('sepa_lang', langCode.toUpperCase());
        this.currentLang = langCode.toUpperCase();
        this.updateCurrentLanguage();
    }

    updateCurrentLanguage() {
        const lang = String(this.currentLang).toLowerCase();
        this.translate.use(lang);
        this.updateAvailableLanguages();
    }

    updateAvailableLanguages() {
        this.availableLangs = this.allLangs.filter(
            lang => lang.short_label !== this.currentLang
        );
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }
}
