import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-tile-component',
    templateUrl: './tile-component.component.html',
    styleUrls: ['./tile-component.component.scss']
})
export class TileComponentComponent {
    @Output() navigationEvent: EventEmitter<any> = new EventEmitter<any>();
    @Input() title = '';
    @Input() icon: string = '';
    @Input() disable = false

    navigate() {
        if (!this.disable)
        this.navigationEvent.emit();
    }
}
