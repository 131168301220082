import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '@ff/user';
import { User } from '@shared-models/user';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent {
    readonly authUser: User;

    constructor(private router: Router, private userService: UserService) {
        this.authUser = new User(this.userService._user.getValue())
    }

    onNavigate(advice: string) {
        this.router.navigate([advice]);
    }
}
