import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { LOCALE_ID, NgModule, Optional, SkipSelf } from '@angular/core';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PreloadAllModules, RouterModule } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { dbConfig } from '@core/db-config';
import { ErrorsInterceptor } from '@core/errors.interceptor';
import { environment } from '@environment/environment';
import { API_CONFIG } from '@ff/api';
import { AT_INTERNET_CONFIG, ATInternetModule } from '@ff/at-internet';
import { AUTH_CONFIG, AuthModule } from '@ff/auth';
import { OAUTH2_CONFIG } from '@ff/oauth2';
import { USER_CONFIG, UserModule } from '@ff/user';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SharedModule } from '@shared/shared.module';
import { RECAPTCHA_SETTINGS, RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings } from 'ng-recaptcha';
import { NgxIndexedDBModule } from 'ngx-indexed-db';
import { AuthModule as AppAuthModule } from '../auth/auth.module';
import { UserModule as AppUserModule } from '../user/user.module';
import { AppComponent } from './components/app/app.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { HomeComponent } from './components/home/home.component';
import { TileComponentComponent } from './components/home/tile-component/tile-component.component';
import { routes } from './core.routes';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
	return new TranslateHttpLoader(http, '/assets/i18n/', '.json');
}

// the second parameter 'fr' is optional
registerLocaleData(localeFr, 'fr');
@NgModule({
	declarations: [AppComponent, HeaderComponent, FooterComponent, HomeComponent, TileComponentComponent],
	providers: [
		{ provide: MAT_DATE_LOCALE, useValue: 'fr-FR' },
		{ provide: LOCALE_ID, useValue: 'fr' },
		{
			provide: API_CONFIG,
			useValue: {
				host: environment.digitalPass.host,
			},
		},
		{ provide: USER_CONFIG, useValue: { host: environment.api.host } },
		{ provide: AUTH_CONFIG, useValue: { host: environment.api.host } },
		{
			provide: OAUTH2_CONFIG,
			useValue: {
				host: environment.api.host,
				endpoints: {
					bearer: '/oauth2/token',
					providers: '/oauth2/providers'
				}
			}
		},
		{ provide: HTTP_INTERCEPTORS, useClass: ErrorsInterceptor, multi: true },
		{
			provide: RECAPTCHA_SETTINGS,
			useValue: { siteKey: environment.reCaptCha.siteKey } as RecaptchaSettings,
		},
		{
			provide: AT_INTERNET_CONFIG,
			useValue: {
				site: environment.xiti.site_id, // Site ID
				autosend: true, // Autosend hits on route change ?
				except: [], // Do not send automatic hits for these routes
				secure: true, // Force HTTPS
				collectDomain: 'logc187.xiti.com', // Data collection domain
				collectDomainSSL: 'logs1187.xiti.com', // Secured data collection domain
			},
		},
	],
	imports: [
		// FF packages
		UserModule,
		AuthModule,
		BrowserModule,
		BrowserAnimationsModule,

		// Angular Packages
		RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),

		// App packages
		SharedModule,
		AppAuthModule,
		AppUserModule,
		RecaptchaModule,
		RecaptchaFormsModule,
		// translate config
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient],
			},
		}),

		ServiceWorkerModule.register('ngsw-worker.js', {
			enabled: environment.production,
			registrationStrategy: 'registerImmediately',
		}),
		NgxIndexedDBModule.forRoot(dbConfig),
		ATInternetModule,
		RouterModule,
	],
	bootstrap: [AppComponent],
})
export class CoreModule {
	public constructor(@Optional() @SkipSelf() parent: CoreModule) {
		if (parent) {
			throw new Error('CoreModule should not be exported then imported by another module!');
		}
	}
}
