import { Component } from '@angular/core';
import { PromptUpdateService } from '@core/services/prompt-update.service';
import { UserService } from '@ff/user';
import { LocalStorageService } from '@shared-services/local-storage.service';
import { OfflineService } from '@shared-services/offline.service';
import { SyncCoachingService } from '@shared-services/sync-coaching.service';
import { fadeAnimation } from '@shared/animations/fade.animation';
import { NgxIndexedDBService } from 'ngx-indexed-db';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	animations: [fadeAnimation],
})
export class AppComponent {

	constructor(
		public checkPwUpdate: PromptUpdateService,
		private store: NgxIndexedDBService,
		private storageLocal: LocalStorageService,
		private syncCoachingService: SyncCoachingService,
		private offlineService: OfflineService,
		private _userService: UserService
	) {
		this.checkPwUpdate.checkForUpdates();
		if (this._userService.getUser().getValue() && this.offlineService.isOnline) {
			this.offlineService.syncStorage();
			this.store.count('user').subscribe(() => {
				this.store.delete('user', this._userService.getUser().getValue().id).subscribe(() => {
					this.store.add('user', this._userService.getUser().getValue());
				});
			});
		}
	}

	getRouterOutletState(outlet: any) {
		return outlet.isActivated ? outlet.activatedRoute : '';
	}
}
