export const environment = {
  production: true,
  api: {
    host: 'https://api-coaching-b2b.mpsprod.alzp.tgscloud.net/api'
  },
  digitalPass:{
    host: 'https://api-coaching-b2b.mpsprod.alzp.tgscloud.net'
  },
  reCaptCha: {
    siteKey: '6LeHMiQaAAAAAOEZMriIuvPLWpB-k0hIuGwWsLlG'
  },
  xiti: {
    site_id: 616695
  },
  hasLoginForm:false

};
