<div class="row mt-1">
  <div class="col-md-4 align-self-center">
    <h1 class="coaching__title mb-3">{{title}}</h1>
  </div>
  <div class="col-md-8 mb-0 pb-0">
    <mat-form-field appearance="fill" class="w-100 mb-0 pb-0">
      <input (keyup)="applyFilter($event.target.value)" matInput [placeholder]="filter_placeholder">
      <mat-icon color="primary" matSuffix>search</mat-icon>
    </mat-form-field>
  </div>
</div>
