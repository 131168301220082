import { Injectable } from '@angular/core';
import { dbConfig } from "@core/db-config";
import { CONSTANT } from '@environment/b2bconstants';
import { NgxIndexedDBService, ObjectStoreMeta } from "ngx-indexed-db";
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class LocalStorageService {
	constructor(private storage: NgxIndexedDBService) {}

	getAll(table: string) {
		return this.storage.getAll(table);
	}

	storeCollection(key: string, datas: any[]) {
		this.storage.clear(key).subscribe((data) => {
			datas.forEach((user) => {
				this.storage.add(key, user);
			});
		});
	}

	updateOrCreate<A>(key, id: number, dataToBeSaved: A): Observable<any> {
		return this.storage.getByKey(key, id).pipe(
			tap((data) => {
				const saveData = { ...data, ...dataToBeSaved, id };
				if (data) {
					this.storage.update(key, saveData);
				} else {
					this.storage.add(key, saveData);
				}
			})
		);
	}

	add(storeName: string, value: any) {
		return this.storage.add(storeName, value);
	}

	getByKey(id: number, coaching: string) {
		return this.storage.getByKey(coaching, id);
	}

	getByIndex(storeName: string, local: string, b) {
		return this.storage.getAllByIndex(storeName, local, b);
	}

	delete(storeName: string, res: any) {
		return this.storage.delete(storeName, res.id);
	}

	deleteAll(storeName: string) {
		return this.storage.clear(storeName);
	}

	cleanDataBase() {
		const storeNames: string[] = [
			CONSTANT.indexedDB.coaching_information,
			CONSTANT.indexedDB.user,
			CONSTANT.indexedDB.coaching_questions,
			CONSTANT.indexedDB.reviews,
			CONSTANT.indexedDB.coaching_list,
		];
		storeNames.forEach((storeName) => {
			this.storage.clear(storeName).subscribe();
		});
	}
}
