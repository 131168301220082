import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-save-button',
    templateUrl: './save-button.component.html',
    styleUrls: ['./save-button.component.scss']
})
export class SaveButtonComponent {
    @Output() saveEvent: EventEmitter<any> = new EventEmitter<any>();
    @Input() title: string = 'general.save_button_label';
    @Input() isDisabled: boolean = false

    save() {
        this.saveEvent.emit();
    }
}
