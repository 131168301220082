import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { CoreModule } from '@ff/core';
import { LoaderModule } from '@ff/loader';
import { ThemeModule } from '@ff/theme';
import { TranslateModule } from '@ngx-translate/core';
import { NavigationButtonComponent } from '@shared-components/navigation-button/navigation-button.component';
import { TableHeaderFilterComponent } from '@shared-components/table-header-filter/table-header-filter.component';
import { DialogConfirmComponent } from '@shared-modals/dialog-confirm/dialog-confirm.component';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';
import { LanguageSwitcherComponent } from './components/language-switcher-select/language-switcher.component';
import { MaterialModule } from './material.module';
import { QuestionFormExpansionComponent } from './components/question-form-expansion/question-form-expansion.component';
import { SaveButtonComponent } from './components/save-button/save-button.component';
import { CoachingCommentFieldComponent } from './components/coaching-comment-field/coaching-comment-field.component';

export const COMPONENT = [
    LanguageSwitcherComponent,
    NavigationButtonComponent,
    QuestionFormExpansionComponent,
    DialogConfirmComponent,
    SaveButtonComponent,
    CoachingCommentFieldComponent,
    TableHeaderFilterComponent
];

@NgModule({
    declarations: [...COMPONENT],
    providers: [],
    imports: [

        // Angular
        ReactiveFormsModule,
        CommonModule,

        // FF Packages
        LoaderModule,
        CoreModule,
        ThemeModule,

        // Angular Material
        MaterialModule,

        //
        TranslateModule,
    ],
    exports: [

        // Angular
        ReactiveFormsModule,
        CommonModule,

        // FF Packages
        LoaderModule,
        CoreModule,
        ThemeModule,
        MaterialModule,
        RecaptchaModule,
        RecaptchaFormsModule,

        TranslateModule,
        ...COMPONENT
    ]
})
export class SharedModule {

    public constructor() {

    }

}
