import { CONSTANT } from '@environment/b2bconstants';
import { DBConfig } from 'ngx-indexed-db';

export const dbConfig: DBConfig = {
	name: 'Coaching',
	version: 16,
	objectStoresMeta: [
		{
			store: CONSTANT.indexedDB.user,
			storeConfig: { keyPath: 'id', autoIncrement: false },
			storeSchema: [{ name: 'email', keypath: 'email', options: { unique: true } }],
		},
		{
			store: CONSTANT.indexedDB.coaching_information,
			storeConfig: { keyPath: 'id', autoIncrement: true },
			storeSchema: [
				{ name: 'is_local', keypath: 'is_local', options: { unique: false } },
				{ name: 'coaching_date', keypath: 'coaching_date', options: { unique: false } },
				{ name: 'sale_manager_id', keypath: 'sale_manager_id', options: { unique: false } },
				{ name: 'sale_represent_id', keypath: 'sale_represent_id', options: { unique: false } },
				{ name: 'sale_manager_name', keypath: 'sale_manager_name', options: { unique: false } },
				{ name: 'sale_represent_name', keypath: 'sale_represent_name', options: { unique: false } },
				{ name: 'customer_prospect_name', keypath: 'customer_prospect_name', options: { unique: false } },
				{ name: 'is_coaching_information', keypath: 'is_coaching_information', options: { unique: false } },
				{ name: 'is_observation_saved', keypath: 'is_observation_saved', options: { unique: false } },
				{ name: 'is_post_visit_saved', keypath: 'is_post_visit_saved', options: { unique: false } },
				{ name: 'is_pre_visit_saved', keypath: 'is_pre_visit_saved', options: { unique: false } },
				{ name: 'is_visit_saved', keypath: 'is_visit_saved', options: { unique: false } },
			],
		},
		{
			store: CONSTANT.indexedDB.coaching_questions,
			storeConfig: { keyPath: 'id', autoIncrement: false },
			storeSchema: [
				{ name: 'pre_visit', keypath: 'pre_visit', options: { unique: false } },
				{ name: 'post_visit', keypath: 'post_visit', options: { unique: false } },
				{ name: 'visit_discovery', keypath: 'visit_discovery', options: { unique: false } },
				{ name: 'visit_intro', keypath: 'visit_intro', options: { unique: false } },
				{ name: 'visit_objection_handling', keypath: 'visit_objection_handling', options: { unique: false } },
				{ name: 'visit_sales_pitch', keypath: 'visit_sales_pitch', options: { unique: false } },
				{ name: 'visit_conclusion', keypath: 'visit_conclusion', options: { unique: false } },
				{ name: 'pre_visit_preparation', keypath: 'pre_visit_preparation', options: { unique: false } },
			],
		},
		{
			store: CONSTANT.indexedDB.coaching_questions_clean,
			storeConfig: { keyPath: 'id', autoIncrement: false },
			storeSchema: [
				{ name: 'pre_visit', keypath: 'pre_visit', options: { unique: false } },
				{ name: 'post_visit', keypath: 'post_visit', options: { unique: false } },
				{ name: 'visit_discovery', keypath: 'visit_discovery', options: { unique: false } },
				{ name: 'visit_intro', keypath: 'visit_intro', options: { unique: false } },
				{ name: 'visit_objection_handling', keypath: 'visit_objection_handling', options: { unique: false } },
				{ name: 'visit_sales_pitch', keypath: 'visit_sales_pitch', options: { unique: false } },
				{ name: 'visit_conclusion', keypath: 'visit_conclusion', options: { unique: false } },
				{ name: 'pre_visit_preparation', keypath: 'pre_visit_preparation', options: { unique: false } },
			],
		},
		{
			store: CONSTANT.indexedDB.reviews,
			storeConfig: { keyPath: 'id', autoIncrement: false },
			storeSchema: [
				{ name: 'pre_visit', keypath: 'pre_visit', options: { unique: false } },
				{ name: 'pre_visit_preparation', keypath: 'pre_visit_preparation', options: { unique: false } },
			],
		},
	],
};
