<div class="container-fluid">
    <h1 class="home-title mb-5">{{'home.title'|translate}}</h1>
    <div class="row mb-4">
        <app-tile-component class="col-6 mb-4"
                            (navigationEvent)="onNavigate('coaching')"
                            [title]="'home.new_coaching'|translate"
                            [disable]="authUser.isSales()"
                            icon="assets/icons/newcoaching.svg">
        </app-tile-component>
        <app-tile-component class="col-6 mb-4" (navigationEvent)="onNavigate('coaching/list')"
                            [title]="'home.coaching_list'|translate"
                            icon="assets/icons/coachinglist.svg">
        </app-tile-component>

        <app-tile-component class="col-6" (navigationEvent)="onNavigate('advice/pitch')"
                            [title]="'home.elevator'|translate" icon="assets/icons/pitch.svg">
        </app-tile-component>

        <app-tile-component *ngIf="authUser.isManager()" class="col-6"
                            (navigationEvent)="onNavigate('advice/manager')"
                            [title]="'home.advice'|translate" icon="assets/icons/advice.svg">
        </app-tile-component>

    </div>
</div>
