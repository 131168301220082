<div class="container-fluid px-0">
    <button [matMenuTriggerFor]="languageSwitcher" class="switcher-button" mat-button>
        {{currentLang}}
        <mat-icon style="line-height: 1.25rem;">keyboard_arrow_down</mat-icon>
    </button>
    <mat-menu #languageSwitcher="matMenu" [overlapTrigger]="false">
        <button (click)="switch(lang.code)" *ngFor="let lang of availableLangs"
                mat-menu-item>{{lang.short_label}}</button>
    </mat-menu>
</div>
