export const CONSTANT = {
	VISIT: 'visit',
	PRE_VISIT: 'pre_visit',
	POST_VISIT: 'post_visit',
	OBSERVATION: 'observation',
	SALES: 'sales',
	indexedDB: {
		coaching_information: 'coaching_information',
		coaching_list: 'coaching_list',
		coaching_questions: 'coaching_questions',
		stepper: 'stepper',
		user: 'user',
		reviews: 'reviews',
		coaching_questions_clean: 'coaching_questions_clean',
	},
};
