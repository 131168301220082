import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { steps } from '@environment/steps';
import { LoaderService } from '@ff/loader';
import { TranslateService } from '@ngx-translate/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class ErrorsInterceptor implements HttpInterceptor {


    constructor(private router: Router,
                private loaderService: LoaderService,
                private translateService: TranslateService,
                private snackBar: MatSnackBar) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            map((event: HttpEvent<any>) => {
                return event;
            }),
            catchError((error: HttpErrorResponse) => {
                console.log(`interceptor error catch ${error.status} ${error.message}`);
                this.loaderService.hide();
                if (error.status === 403) {
                    this.navigateAndAlert(steps.COACHING_LIST, error.error.message);
                }
                if (error.status === 404) {
                    this.navigateAndAlert(steps.COACHING_LIST, this.translateService.instant('general.error.coaching_not_fount'));
                }
                return throwError(error);
            })
        );
    }

    private navigateAndAlert(route, message: string) {
        this.router.navigate([route]).then(() => {
            this.snackBar.open(message, 'ok', {
                duration: 5000
            });
        });
    }
}
