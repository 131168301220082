<h4 mat-dialog-title class="text-uppercase">{{'reset_password.title'|translate}}</h4>
<form mat-dialog-content [formGroup]="form" (ngSubmit)="submit()" novalidate>
    <p>{{'reset_password.sub_title'|translate}}</p>
    <mat-form-field class="w-100">
        <input matInput prefixIcon="envelope-o" placeholder="email@domain.com" type="email" formControlName="email" />
    </mat-form-field>
    <re-captcha badge="inline" formControlName="recaptcha"></re-captcha>
</form>
<div mat-dialog-actions class="d-flex justify-content-start">
    <button mat-raised-button class="align-self-start py-1" color="primary" [disabled]="form.invalid" (click)="submit()">
        <mat-icon>arrow_right</mat-icon>
        {{'reset_password.send'|translate}}
    </button>
</div>
