<form [formGroup]="form" class="pb-4">
    <h2 *ngIf="title">{{title}}</h2>
    <h3 *ngIf="subTitle">{{subTitle}}</h3>
    <mat-form-field appearance="fill" class="w-100">
        <mat-label>{{label}}</mat-label>
        <textarea title="Observations"  [formControlName]="controlName" matInput
                  [placeholder]="placeholder"></textarea>
        <mat-hint class="info-comment" *ngIf="info">{{info}}</mat-hint>
    </mat-form-field>
</form>
