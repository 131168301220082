export const endpoints = {
  login: "/auth/login",
  admin_forget_password: "/user/password/email",
  user: "/user",
  switch_language: "/switch-language",
  current_language: "/current-language",
  languages: "/languages",
  logout: "/auth/logout",
  coaching_users: "/coaching/users",
  coaching_managers: "/coaching/managers",
  coaching_sales: "/coaching/sales",
  coaching: "/coaching",
  coaching_question: "/coaching/:coaching_id/question/:question_id",
  pre_visit: "pre_visit",
  coaching_observations: "/coaching",
  report: "/coaching/:id/report",
  share_report: "/coaching/:id/share",
  back_up_coaching: "backup/coaching",
  coaching_stepper: "/coaching/:id/stepper"

};
