import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '@environment/environment';
import { AuthService } from '@ff/auth';
import { LoaderService } from '@ff/loader';
import { OAuth2Service } from '@ff/oauth2';
import { UserService } from '@ff/user';
import { LocalStorageService } from '@shared-services/local-storage.service';
import { OfflineService } from '@shared-services/offline.service';
import { BehaviorSubject } from 'rxjs';
import { ResetPasswordLinkComponent } from '../reset-password-link/resetPasswordLink.component';
import { ResetPasswordComponent } from '../reset-password/resetPassword.component';

@Component({
	selector: 'app-auth-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
	public loginForm: FormGroup;
	public error: any;
	hasLoginForm= environment.hasLoginForm
	/**
	 * Reset password link modal
	 */
	public showResetPasswordLinkModal: boolean;

	/**
	 * Reset password modal
	 */
	public showResetPasswordModal: boolean;

	public token: string;
	public email: string;

	constructor(
		private _authService: AuthService,
		fb: FormBuilder,
		private _oauth2: OAuth2Service,
		private _router: Router,
		private _userService: UserService,
		private _activatedRoute: ActivatedRoute,
		private _matDialog: MatDialog,
		private _snackBar: MatSnackBar,
		private storageLocal: LocalStorageService,
		private offlineService: OfflineService,
		private _loaderService: LoaderService
	) {
		this.error = {};

		this.loginForm = fb.group({
			email: ['', Validators.required],
			password: ['', Validators.required],
		});

		this.showResetPasswordLinkModal = false;

		this._activatedRoute.queryParams.subscribe((params: any) => {
			if (params.token) {
				this.token = params.token;
				this.email = params.email;
				this.showResetPasswordDialog();
			}
			if (params._error === 'unauthorized') {
				this._snackBar
					.open('Ces identifiants ne correspondent pas à nos enregistrements', 'Close')
					.afterDismissed()
					.subscribe(() => {
						this._router.navigate(['']);
					});
			}
		});


		if (this._userService.getUser().getValue()) {
			this._router.navigate(['/home']);
		}
	}

	public redirect(url: string) {
		window.location.href = url;
	}

	public login(form: FormGroup) {
		// Prevent spam submit
		if (form.valid && !this._loaderService.isVisible()) {
			this._loaderService.show();

			this._authService
				.login(form)
				.then(() => {
					window.location.reload()
				})
				.catch((response) => {
					this._loaderService.hide();
					this._snackBar.open(response.error.message, 'Close', {
						duration: 3000,
					});
				});
		}
	}

	public showResetPasswordLinkDialog() {
        window.scrollTo(0, 0);
        window.setTimeout(() => {
            this._matDialog.open(ResetPasswordLinkComponent);
        }, 100);

	}

	public showResetPasswordDialog() {
		this._matDialog.open(ResetPasswordComponent, {
			data: { token: this.token, email: this.email },
		});
	}

	public getOAuth2Providers(): BehaviorSubject<any> {
		return this._oauth2.getProviders();
	}

	loginWithOAuth2(url) {
		this._loaderService.show()
		this._oauth2.login(url);
	}
}
