import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SwUpdate } from '@angular/service-worker';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
	providedIn: 'root',
})
export class PromptUpdateService {
	constructor(public updates: SwUpdate, private translateService: TranslateService, public snackBar: MatSnackBar) {
		updates.available.subscribe((event) => {
			this.promptUser(event);
		});
	}

	public checkForUpdates(): void {
		this.updates.available.subscribe((event) => this.promptUser(event));
	}
	// If there is an update, promt the user
	private promptUser(e): void {
		if (e.available) {
			const snackBarRef = this.snackBar.open(this.translateService.instant('general.pwa_prompt'), 'Update');
			snackBarRef.onAction().subscribe(() => document.location.reload());
		}
	}
}
