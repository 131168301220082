import { Component, Input } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { AuthService } from '@ff/auth';
import { UserService } from '@ff/user';
import { User } from '@shared-models/user';
import { OfflineService } from '@shared-services/offline.service';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
	user: User;
	@Input() public nav: MatSidenav;

	constructor(
		public userService: UserService,
		public offlineService: OfflineService,
		public authService: AuthService,
		private router: Router
	) {
		this.userService.getUser().subscribe((user) => {
			this.user = new User(user);
		});
	}

	goHome() {
		this.router.navigate(['home']);
	}
}
