export const steps = {
    ROOT: '',
    HOME: 'home',
    AUTH: 'auth',
    LOGIN: 'login',
    ADVICE: 'advice',
    MANAGER: 'manager',
    ADVICE_MANAGER: 'advice/manager',
    ADVICE_PITCH: 'advice/pitch',
    PITCH: 'pitch',
    COACHING: 'coaching',
    COACHING_LIST: 'coaching/list',
    LIST: 'list',
    LOGOUT: 'logout',
    NEW: 'new',
    INFORMATION: 'information',
    INFORMATION_EDIT: 'information/:id',
    PRE_VISIT_EDIT: 'previsit/:id',
    VISIT_EDIT: 'visit/:id',
    POST_VISIT_EDIT: 'postvisit/:id',
    OBSERVATIONS: 'observations/:id', ADMIN: 'administration',
    COACHING_REVIEW: ':id/review'

};
