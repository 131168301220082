import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CONSTANT } from '@environment/b2bconstants';
import { LoaderService } from '@ff/loader';
import { Coaching } from '@shared-models/coaching';
import { CoachingQuestions } from '@shared-models/coaching-questions';
import { LocalStorageService } from '@shared-services/local-storage.service';
import { SyncCoachingService } from '@shared-services/sync-coaching.service';
import { forkJoin, Observable, of, Subject } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class OfflineService {
	private internalConnectionChanged = new Subject<boolean>();

	constructor(
		private storageLocal: LocalStorageService,
		private _snackBar: MatSnackBar,
		private syncCoaching: SyncCoachingService,
		private loader: LoaderService
	) {
		// listen for the online/offline events
		window.addEventListener('online', () => this.updateOnlineStatus('online'));
		window.addEventListener('offline', () => this.updateOnlineStatus('offline'));
	}

	// return the connection state
	get connectionChanged() {
		return this.internalConnectionChanged.asObservable();
	}

	get isOnline() {
		return !!window.navigator.onLine;
	}

	private updateOnlineStatus(status: string) {
		if (status === 'online') {
			this.syncStorage();
		} else {
			this._snackBar.open('Your are now in offline mode', 'ok');
		}
		this.internalConnectionChanged.next(window.navigator.onLine);
	}

	async syncStorage(init = false): Promise<any> {
		if (!this.loader.isVisible()) {
			this.loader.show('Data synchronization');
		}
		let coachingInformation$: Observable<any[]> = of([]);
		let coachingQuestion$: Observable<CoachingQuestions[]> = of([]);
		if (!init) {
			coachingInformation$ = this.storageLocal.getAll(CONSTANT.indexedDB.coaching_information);
			coachingQuestion$ = (this.storageLocal.getAll(CONSTANT.indexedDB.coaching_questions) as Observable<CoachingQuestions[]>);
		}
		await forkJoin([coachingInformation$, coachingQuestion$]).subscribe(([coachingInformation, coachingQuestions]) => {
			this.syncCoaching
				.syncCoachingData({ information: coachingInformation, questions: coachingQuestions })
				.subscribe(({ myData, initQuestion }: any) => {
					this.storageLocal.deleteAll(CONSTANT.indexedDB.coaching_information).subscribe(() => {
						this.loader.hide();
						myData.forEach((d) => {
							const { questions, review, ...rest } = d;
							const q = { ...questions, id: rest.id };
							const r = { ...review, id: rest.id };
							this.storageLocal.add(CONSTANT.indexedDB.coaching_information, rest).subscribe();
							this.storageLocal.deleteAll(CONSTANT.indexedDB.coaching_questions).subscribe(() => {
								this.storageLocal.add(CONSTANT.indexedDB.coaching_questions, q).subscribe();
							});
							this.storageLocal.deleteAll(CONSTANT.indexedDB.reviews).subscribe(() => {
								this.storageLocal.add(CONSTANT.indexedDB.reviews, r).subscribe();
							});
						});
						this.storageLocal.deleteAll(CONSTANT.indexedDB.coaching_questions_clean).subscribe((rt) => {
							this.storageLocal.add(CONSTANT.indexedDB.coaching_questions_clean, { ...initQuestion, id: 1 });
						});
					});
				});
		});
	}
}
