import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-table-header-filter',
  templateUrl: './table-header-filter.component.html',
  styleUrls: ['./table-header-filter.component.scss']
})
export class TableHeaderFilterComponent {
  @Input() filter_placeholder: string;
  @Output() filterInputEvent: EventEmitter<string> = new EventEmitter<string>();
  @Input() title: string;

  applyFilter = (value: string) => this.filterInputEvent.emit(value);

}
