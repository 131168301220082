
export class User {
    id: number;
    name: string;
    email?: string;
    last_name: string;
    first_name: string;
    full_name: string;
    role:string;
    sales: User[]
    igg:string;
    creator_id: number;

    constructor(data = {}) {
        Object.assign(this, data);
    }

    isAuthenticatedUser = (user: User) => this.id === user.id;
    isSales = () => this.role === 'sales';
    isAdmin = (): boolean => this.role === 'admin';
    isKeyBusinessUser = (): boolean => this.role === 'key_business_user';
    isSalesDirector = (): boolean => this.role === 'sales_director';
    isManager = (): boolean => this.role === 'manager';

    canManageUser = () => (this.isAdmin() || this.isKeyBusinessUser() || this.isSalesDirector() || this.isManager())

    canSeeFollowUp = () => this.isAdmin() || this.isKeyBusinessUser() ;
}

