import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-navigation-button',
    templateUrl: './navigation-button.component.html',
    styleUrls: ['./navigation-button.component.scss']
})
export class NavigationButtonComponent {
    @Input() step: string = null;
    @Output() navigationBackEvent: EventEmitter<any> = new EventEmitter<any>();

    goBack() {
        this.navigationBackEvent.emit();
    }
}
