<mat-accordion class="question-form__accordion">
    <mat-expansion-panel class="pl-0 py-3" [expanded]="expanded">
        <mat-expansion-panel-header  class="mb-3">
            <mat-panel-title class="question-form__expansion_title">
                {{title}}
            </mat-panel-title>
            <mat-panel-description>
            </mat-panel-description>
        </mat-expansion-panel-header>
        <mat-divider class="pb-2"></mat-divider>
        <div *ngFor="let q of form.controls;index as i ">
            <p class="question-form__expansion_table_question" *ngIf="translateService.currentLang === 'en'">{{q.get('question_en').value}}</p>
            <p class="question-form__expansion_table_question" *ngIf="translateService.currentLang === 'fr'">{{q.get('question_fr').value}}</p>
            <p class="question-form__expansion_table_question" *ngIf="translateService.currentLang === 'es'">{{q.get('question_es').value}}</p>
            <div class="d-flex justify-content-start w-100">
                <mat-button-toggle-group class="mb-2 flex-wrap" [formControl]="q.get('answer')" >
                    <mat-button-toggle value="beginner">{{'general.answer_button.beginner'|translate}}</mat-button-toggle>
                    <mat-button-toggle value="confirmed">{{'general.answer_button.confirmed'|translate}}</mat-button-toggle>
                    <mat-button-toggle value="master">{{'general.answer_button.master'|translate}}</mat-button-toggle>
                </mat-button-toggle-group>
            </div>
            <mat-divider class="pb-2"></mat-divider>
        </div>
    </mat-expansion-panel>
</mat-accordion>
