<div class="container-fluid px-2">
    <div class="row no-gutters">
        <h1 class="col-12 pb-3">{{'user.title'|translate}}</h1>
        <div class="d-flex align-items-center w-100 mb-3">
            <small>{{'user.required'|translate}}</small>
            <a class="ml-auto d-lg-flex align-items-center d-none" routerLink="/auth/logout">
                <mat-icon>arrow_right</mat-icon>
                Logout
            </a>
        </div>
        <div class="d-flex user-info justify-content-center flex-column col-12 mb-4" [formGroup]="getUserInfoForm()">
            <div class="row">
                <h2 class="col-12">{{'user.form.title'|translate}}</h2>
                <mat-form-field class="col-12 col-lg-4">
                    <input type="text" matInput [placeholder]="'user.form.first_name'|translate"
                           formControlName="first_name"/>
                </mat-form-field>
                <mat-form-field class="col-12 col-lg-4">
                    <input type="text" matInput [placeholder]="'user.form.last_name'|translate"
                           formControlName="last_name"/>
                </mat-form-field>
                <mat-form-field class="col-12 col-lg-4">
                    <input type="email" matInput [placeholder]="'user.form.email'|translate" formControlName="email"/>
                </mat-form-field>
                <div class="col-12 d-flex justify-content-end pt-3">
                    <button mat-raised-button color="primary" (click)="saveUserInfo()"
                            [disabled]="getUserInfoForm().invalid">
                        <mat-icon class="d-none d-lg-inline">arrow_right</mat-icon>
                        {{'user.form.button_label'|translate}}
                    </button>
                </div>
            </div>
        </div>
        <div class="d-flex user-password justify-content-center flex-column col-12 mb-4"
             [formGroup]="getUserPasswordForm()">
            <div class="row">
                <h2 class="col-12 d-flex align-items-center">{{'user.password.title'|translate}}
                    <mat-icon
                            class="ml-3 hint" matTooltipClass="hint-text"
                            [matTooltip]="'user.password.hint'|translate">
                        info
                    </mat-icon>
                </h2>
                <mat-form-field class="col-12 col-lg-4">
                    <input type="password" matInput [placeholder]="'user.password.current_password'|translate" formControlName="oldPassword"/>
                </mat-form-field>
                <mat-form-field class="col-12 col-lg-4">
                    <input type="password" matInput [placeholder]="'user.password.new_password'|translate" formControlName="newPassword"/>
                </mat-form-field>
                <mat-form-field class="col-12 col-lg-4">
                    <input type="password" matInput [placeholder]="'user.password.confirm_password'|translate"
                           formControlName="newPassword_confirmation"/>
                </mat-form-field>
                <div class="col-12 d-flex justify-content-end pt-3">
                    <button mat-raised-button color="primary" (click)="saveUserPassword()"
                            [disabled]="getUserPasswordForm().invalid">
                        <mat-icon class="d-none d-lg-inline">arrow_right</mat-icon>
                        {{'user.password.button_label'|translate}}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
