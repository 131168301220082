import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment/environment';
import { Coaching } from '@shared-models/coaching';

@Injectable({
	providedIn: 'root',
})
export class SyncCoachingService {
	constructor(private http: HttpClient) {}

	syncCoachingData = (data: any) => this.http.post(`${environment.api.host}/coaching/sync`, data);
}
