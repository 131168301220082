import { Component, Input } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';

@Component({
    selector: 'app-coaching-comment-field',
    templateUrl: './coaching-comment-field.component.html',
    styleUrls: ['./coaching-comment-field.component.scss']
})
export class CoachingCommentFieldComponent {
    @Input() form: FormGroup | AbstractControl;
    @Input() controlName: string;
    @Input() placeholder: string;
    @Input() label: string;
    @Input() title: string;
    @Input() subTitle: string;
    @Input() info: string;
}
