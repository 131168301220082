<div class="container-fluid h-100 d-flex flex-column">
    <div class="row justify-content-center">
        <form class="login-form d-flex align-items-center justify-content-center my-4" (ngSubmit)="login(loginForm)" [formGroup]="loginForm" novalidate>
          <mat-card class="col-12 col-md-8 col-lg-6 col-xl-5 p-8 no-shadow text-center">
            <mat-card-content class="d-flex flex-column" *ngIf="hasLoginForm; else digitalPass">
                <h2>{{'auth.title'|translate}}</h2>
                <mat-form-field class="mb-3" appearance="fill">
                    <mat-label>{{'auth.email'|translate}}</mat-label>
                    <input matInput type="email" formControlName="email" [placeholder]="'auth.email'|translate" required />
                    <mat-icon matSuffix>perm_identity</mat-icon>
                </mat-form-field>
                <mat-form-field class="mb-3" appearance="fill">
                    <mat-label>{{'auth.password'|translate}}</mat-label>
                   <input matInput type="password" formControlName="password"  [placeholder]="'auth.password'|translate" required />
                   <mat-icon matSuffix>lock</mat-icon>
                </mat-form-field>
                <p class="forgotten-password align-self-start d-flex align-items-center pointer mb-4" (click)="showResetPasswordLinkDialog()">
                    <mat-icon color="primary">arrow_right</mat-icon>
                    <span>{{'auth.reset_password'|translate}}</span>
                </p>
                <button mat-raised-button class="align-self-start py-1 w-100" color="primary" [disabled]="!loginForm.valid">
                    <mat-icon>arrow_right</mat-icon>
                    {{'auth.login'|translate}}
                </button>
                <ng-container *ngIf="(getOAuth2Providers() | async).length">
                    <a *ngFor="let provider of getOAuth2Providers() | async" (click)="loginWithOAuth2(provider.url)"
                       class="d-flex align-items-center justify-content-center ff-link digital-pass mt-4">
                        <mat-icon>arrow_right</mat-icon>
                        {{'auth.connect_via'|translate:{value: provider.label} }}
                    </a>
                </ng-container>
            </mat-card-content>
              <ng-template #digitalPass>
                  <h2>{{'auth.title'|translate}}</h2>
                  <ng-container *ngIf="(getOAuth2Providers() | async).length">
                      <button mat-raised-button color="primary"  *ngFor="let provider of getOAuth2Providers() | async" (click)="loginWithOAuth2(provider.url)">
                          <mat-icon>arrow_right</mat-icon>
                          {{'auth.connect_via'|translate:{value: provider.label} }}
                      </button>
                  </ng-container>
              </ng-template>
          </mat-card>
        </form>
    </div>
</div>
