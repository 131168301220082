<header>
  <div class="pt-3 d-flex align-items-center justify-content-between">
    <img src="assets/logo.svg" title="TotalEnergies" alt="TotalEnergies" class="logo" (click)="goHome()" />
    <div class="m-auto d-flex align-items-center">
      <h1 class="header-title">{{'header.title'|translate}}</h1>
    </div>
    <ng-container *ngIf="userService.getUser() | async">
      <span class="pointer  d-flex align-items-center user" [matMenuTriggerFor]="account">
        <span class="username">{{userService.getUser().getValue().full_name}}</span>
           <img src="assets/icons/head.svg" class="ml-md-2" width="27" height="27" alt="auth icon">
      </span>
      <mat-menu class="mt-2" #account="matMenu" [overlapTrigger]="false">
        <button mat-menu-item class="pr-5" routerLink="/user/my-account">{{'header.my_account' | translate}}</button>
        <button mat-menu-item class="pr-5" *ngIf="user.canSeeFollowUp() && offlineService.isOnline" routerLink="/administration/user-guide">{{'header.user_guide' | translate}}</button>
        <button mat-menu-item class="pr-5" *ngIf="user.canSeeFollowUp() && offlineService.isOnline" routerLink="/administration/sales-transfert">{{'header.sales_transfert' | translate}}</button>
        <button mat-menu-item class="pr-5" *ngIf="user.canManageUser() && offlineService.isOnline" routerLink="/administration/users">{{'header.admin' | translate}}</button>
        <button mat-menu-item class="pr-5" *ngIf="user.canSeeFollowUp() && offlineService.isOnline" routerLink="/administration/follow-up">{{'header.follow' | translate}}</button>
        <button mat-menu-item routerLink="/auth/logout" *ngIf="offlineService.isOnline">{{'header.logout' | translate}}</button>
      </mat-menu>
    </ng-container>
    <app-language-switcher></app-language-switcher>
  </div>
</header>
