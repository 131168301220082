import { Component, Input, OnInit } from '@angular/core';
import { FormArray } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Question } from '@shared-models/question';

@Component({
    selector: 'app-question-form-expansion',
    templateUrl: './question-form-expansion.component.html',
    styleUrls: ['./question-form-expansion.component.scss']
})
export class QuestionFormExpansionComponent implements OnInit {
    constructor(public translateService:TranslateService) {

    }
    @Input() question: Question[];
    @Input() formArray: string;
    @Input() title: string;
    @Input() expanded: boolean = false;
    @Input() form: FormArray;
    @Input() disableForm = false;

    ngOnInit(): void {
        if (this.disableForm) {
            this.form.disable();
        }
    }
}
