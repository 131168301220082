import { Routes } from '@angular/router';
import { HomeComponent } from '@core/components/home/home.component';
import { AdminGuard } from '@core/guards/admin.guard';
import { steps } from '@environment/steps';
import { LoggedInGuard } from '@ff/core';

export const routes: Routes = [
    {path: steps.HOME, component: HomeComponent, canActivate: [LoggedInGuard]},
    {
        path: steps.ADVICE,
        canActivate: [LoggedInGuard],
        loadChildren: () => import('../advice/advice.module').then(m => m.AdviceModule)
    },
    {
        path: steps.COACHING,
        canActivate: [LoggedInGuard],
        loadChildren: () => import('../coaching/coaching.module').then(m => m.CoachingModule)
    },
    {
        path: steps.ADMIN,
        canActivate: [LoggedInGuard, AdminGuard],
        loadChildren: () => import('../admin/admin.module').then(m => m.AdminModule)
    },
    {path: '**', redirectTo: steps.HOME}
];
