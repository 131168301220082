<!-- ff-theme is an invisible component that applies "Total" CSS theme from @ff/angular-core -->
<ff-theme>
  <mat-sidenav-container class="h-100">
      <mat-sidenav-content class="d-flex flex-column">
          <app-header></app-header>
          <main [@fadeAnimation]="getRouterOutletState(o)">
              <router-outlet #o="outlet"></router-outlet>
          </main>
          <app-footer></app-footer>
      </mat-sidenav-content>
  </mat-sidenav-container>
</ff-theme>
